<template>
  <form class="search-box" @submit.prevent="send()">
    <input
      ref="searchBox"
      v-model="searchTerm"
      :placeholder="placeholder"
      :class="['search-box__input', baseClass ? `${baseClass}__input` : null]"
      type="search"
      @input="send" />
    <img
      alt="Search icon"
      class="search-icon"
      src="\assets\icons\search-dark.svg" />
  </form>
</template>

<script>
  export default {
    name: "SearchBox",
    components: {},
    props: {
      placeholder: {
        type: String,
        default: "",
      },
      parsedTerm: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        searchTerm: "",
        baseClass: null,
        interval: null,
      };
    },
    computed: {
      iconContainerClass() {
        if (!this.baseClass) {
          return "search-box__icon";
        }
        return `search-box__icon ${this.baseClass}__icon`;
      },
    },
    mounted() {
      this.searchTerm = this.parsedTerm;
      const searchbox = this.$refs.searchBox;
      searchbox.focus();
    },
    methods: {
      send() {
        clearTimeout(this.interval);
        this.interval = setTimeout(() => {
          this.$emit("input", this.searchTerm);
        }, 400);
      },
    },
  };
</script>
