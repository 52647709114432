/* eslint-disable global-require */
<template>
  <nav v-if="navigation" class="navigation" :class="{ 'is-scrolled': isScrolled }">
    <NavigationDesktop :menu="navigation" class="navigation-desktop"/>
    <NavigationMobile :menu="navigation" class="navigation-mobile" />
  </nav>
</template>

<script>
  import NavigationDesktop from "@/components/navigation/NavigationDesktop.vue";
  import NavigationMobile from "@/components/navigation/NavigationMobile.vue";
  import NavMobile from "@/components/NavMobile.vue";

  export default {
    name: "NavMenu",
    components: { NavMobile, NavigationDesktop, NavigationMobile },
    props: {
      navigation: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isScrolled: true,
      };
    },
    mounted() {
      this.onScroll();
      document.body.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
      document.body.removeEventListener("scroll", this.onScroll);
    },
    methods: {
      onScroll() {
        this.isScrolled = document.body.scrollTop > 50;
      },
    },
  };
</script>
