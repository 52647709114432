<template>
  <a :href="item.url" class="search-result">
    <h3 v-if="item.title">{{ item.title }}</h3>
    <time :datetime="date">{{ date }}</time>
    <p class="search-result__uri">
      <img
        v-if="imageType && imageType.src"
        :alt="imageType.name"
        :src="imageType.src"
        class="search-result__icon" />
      /{{ item.uri }}
    </p>
    <p v-if="description">{{ description }}</p>
    <chevron-right></chevron-right>
  </a>
</template>

<script>
  export default {
    name: "SearchItem",
    props: {
      item: {
        type: Object,
        required: true,
      },
      searchMode: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        possibleTypes: [
          "actueel",
          "evenementen",
          "nieuws",
          "onderwerpen",
          "opleidingen-trainingen",
          "lidmaatschap",
          "over-ons",
          "kennis-tools",
          "tools",
          "lobby",
        ],
      };
    },
    computed: {
      date() {
        return new Date(this.item.postDate * 1000).toLocaleDateString();
      },
      imageType() {
        if (this.possibleTypes.includes(this.item.type)) {
          return {
            name: this.item.type,
            src: `/assets/icons/search/menu-icon_${this.item.type}--grey.svg`,
          };
        }
        return null;
      },
      description() {
        const maxLength = 200;
        const content = this.item.description;

        if (content.length > maxLength) {
            return content.substring(0, maxLength) + "...";
        }

        return content;
      },
    },
  };
</script>
