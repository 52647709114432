<template>
  <div class="event-detail">
    <ul class="event-detail__list">
      <li
        v-for="(item, index) in limitedData"
        :key="`tableItem-${index}`"
        class="event-detail__list-item">
        <div v-if="item.soldOut" class="event-detail__list-item__soldout">
          UITVERKOCHT!
        </div>
        <div
          v-else-if="item.courseFull"
          class="event-detail__list-item__soldout">
          VOLGEBOEKT!
        </div>

        <h3 class="event-detail__list-item__location">
          {{ item.location }}
        </h3>

        <span v-if="item.address" class="event-detail__list-item__address">
          {{ item.address }}
        </span>

        <div class="event-detail__list-item__planning-date">
          {{ item.startDate }}
        </div>

        <a
          :href="`${item.link.url}`"
          class="event-detail__list-item__link"
          :target="`${item.link.target}`">
          <i class="fas fa-icon fa-chevron-right"></i>
          {{ item.link.title }}
        </a>
      </li>
    </ul>

    <button
      v-if="info.length > limit"
      class="event-detail__loadmore"
      @click="increaseLimit()">
      {{ buttontext }}
    </button>
  </div>
</template>

<script>
  export default {
    name: "TableInfo",
    props: {
      info: {
        type: Array,
        required: true,
      },
      buttontext: {
        type: String,
        default: "Bekijk meer",
      },
    },
    data() {
      return {
        limit: 6,
      };
    },
    computed: {
      limitedData() {
        return this.info.slice(0, this.limit);
      },
    },
    methods: {
      increaseLimit() {
        this.limit += 6;
      },
    },
  };
</script>
