<template>
  <div>
    <div :style="{ width: `${widthInt}px` }" class="mobile-navigation">
      <div
        :style="{
          width: `${widthInt * (totalSubsets.length + 1)}px`,
          transform: `translateX(-${transform}px)`,
        }"
        class="mobile-navigation__container">
        <div class="mobile-navigation__level one">
          <template v-for="(levelOneItem, key) of menu">
            <div
              v-if="levelOneItem.title"
              :key="key"
              class="mobile-navigation__level-item"
              @click="createSubSet(levelOneItem)">
              <img
                v-if="
                  levelOneItem.menuItemIcon && levelOneItem.menuItemIcon.length
                "
                :alt="levelOneItem.title"
                :src="levelOneItem.menuItemIcon[0].url" />
              <span
                v-if="levelOneItem.title"
                class="mobile-navigation__level-item-title l1"
                >{{
                  levelOneItem.customTitle
                    ? levelOneItem.customTitle
                    : levelOneItem.title
                }}</span
              >
              <svg class="chevron-right" viewBox="0 -256 1792 2016">
                <path
                  d="M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z"
                  fill="currentColor" />
              </svg>
            </div>
          </template>
        </div>
        <div
          v-for="(set, key) in totalSubsets"
          :key="key"
          class="mobile-navigation__level l2">
          <div v-if="set.title" class="mobile-navigation__level-item">
            <a
              v-if="set.url && set.url.url"
              class="mobile-navigation__level-item-title"
              :href="set.url.url">
              <strong>{{ set.title }}</strong>
            </a>
            <strong v-else class="mobile-navigation__level-item-title">{{
              set.title
            }}</strong>
            <svg
              v-if="set.url && set.url.url"
              class="chevron-right"
              viewBox="0 -256 1792 2016">
              <path
                d="M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z"
                fill="currentColor" />
            </svg>
          </div>
          <div
            v-for="(child, key1) in set.children"
            :key="key1"
            class="mobile-navigation__level-item l3"
            @click="createSubSet(child)">
            <a
              v-if="child.externalMenuItemLink"
              :href="child.externalMenuItemLink"
              class="mobile-navigation__level-item-title"
              rel="noopener noreferrer"
              target="_blank"
              >{{ child.customTitle ? child.customTitle : child.title }}</a
            >
            <template
              v-else-if="
                child.menuItemContentLink &&
                child.menuItemContentLink.length &&
                child.menuItemContentLink[0].uri
              ">
              <a
                class="mobile-navigation__level-item-title"
                :href="child.menuItemContentLink[0].uri"
                >{{
                  child.customTitle
                    ? child.customTitle
                    : child.menuItemContentLink[0].title
                }}</a
              >
            </template>

            <!-- This span is being printed when no link has been provided but only
            functions as a parent-->
            <span
              v-else-if="child.children && child.title"
              class="mobile-navigation__level-item-title"
              >{{ child.customTitle ? child.customTitle : child.title }}
            </span>
            <svg
              v-if="child.children && child.children.length > 0"
              class="chevron-right"
              viewBox="0 -256 1792 2016">
              <path
                d="M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z"
                fill="currentColor" />
            </svg>
          </div>

          <a
            v-if="animationLevel > 1"
            class="mobile-navigation__back"
            href="#"
            @click.prevent="removeSubSet">
            <v-icon name="chevron-left" />Terug
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    name: "MobileNavigation",
    props: {
      menu: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        widthInt: window.innerWidth,
        animationLevel: 1,
        totalSubsets: [],
      };
    },
    computed: {
      transform() {
        const px = this.widthInt * this.animationLevel;
        return px - px / this.animationLevel;
      },
    },
    mounted() {
      window.addEventListener("resize", this.setMenuWidth);
    },
    destroyed() {
      window.removeEventListener("resize", this.setMenuWidth);
    },
    methods: {
      ...mapActions({
        toggleMenuState: "navigation/toggleMenuState",
        closeMenu: "navigation/closeMenu",
      }),
      setMenuWidth() {
        this.widthInt = window.innerWidth;
      },
      createSubSet(set) {
        if (!set.children) {
          return;
        }
        this.totalSubsets.push(set);
        this.animationLevel++;
      },
      removeSubSet() {
        this.animationLevel--;
        setTimeout(() => {
          this.totalSubsets.pop();
        }, 300);
      },
      toPage(page) {
        if (!page) {
          return;
        }
        this.closeMenu();
        this.$router.push({ path: "/" + page });
        setTimeout(() => {
          // might get removed
          this.totalSubsets = [];
          this.animationLevel = 1;
        }, 300);
      },
    },
  };
</script>
