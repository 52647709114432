var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mobile-navigation",style:({ width: `${_vm.widthInt}px` })},[_c('div',{staticClass:"mobile-navigation__container",style:({
        width: `${_vm.widthInt * (_vm.totalSubsets.length + 1)}px`,
        transform: `translateX(-${_vm.transform}px)`,
      })},[_c('div',{staticClass:"mobile-navigation__level one"},[_vm._l((_vm.menu),function(levelOneItem,key){return [(levelOneItem.title)?_c('div',{key:key,staticClass:"mobile-navigation__level-item",on:{"click":function($event){return _vm.createSubSet(levelOneItem)}}},[(
                levelOneItem.menuItemIcon && levelOneItem.menuItemIcon.length
              )?_c('img',{attrs:{"alt":levelOneItem.title,"src":levelOneItem.menuItemIcon[0].url}}):_vm._e(),(levelOneItem.title)?_c('span',{staticClass:"mobile-navigation__level-item-title l1"},[_vm._v(_vm._s(levelOneItem.customTitle ? levelOneItem.customTitle : levelOneItem.title))]):_vm._e(),_c('svg',{staticClass:"chevron-right",attrs:{"viewBox":"0 -256 1792 2016"}},[_c('path',{attrs:{"d":"M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z","fill":"currentColor"}})])]):_vm._e()]})],2),_vm._l((_vm.totalSubsets),function(set,key){return _c('div',{key:key,staticClass:"mobile-navigation__level l2"},[(set.title)?_c('div',{staticClass:"mobile-navigation__level-item"},[(set.url && set.url.url)?_c('a',{staticClass:"mobile-navigation__level-item-title",attrs:{"href":set.url.url}},[_c('strong',[_vm._v(_vm._s(set.title))])]):_c('strong',{staticClass:"mobile-navigation__level-item-title"},[_vm._v(_vm._s(set.title))]),(set.url && set.url.url)?_c('svg',{staticClass:"chevron-right",attrs:{"viewBox":"0 -256 1792 2016"}},[_c('path',{attrs:{"d":"M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z","fill":"currentColor"}})]):_vm._e()]):_vm._e(),_vm._l((set.children),function(child,key1){return _c('div',{key:key1,staticClass:"mobile-navigation__level-item l3",on:{"click":function($event){return _vm.createSubSet(child)}}},[(child.externalMenuItemLink)?_c('a',{staticClass:"mobile-navigation__level-item-title",attrs:{"href":child.externalMenuItemLink,"rel":"noopener noreferrer","target":"_blank"}},[_vm._v(_vm._s(child.customTitle ? child.customTitle : child.title))]):(
              child.menuItemContentLink &&
              child.menuItemContentLink.length &&
              child.menuItemContentLink[0].uri
            )?[_c('a',{staticClass:"mobile-navigation__level-item-title",attrs:{"href":child.menuItemContentLink[0].uri}},[_vm._v(_vm._s(child.customTitle ? child.customTitle : child.menuItemContentLink[0].title))])]:(child.children && child.title)?_c('span',{staticClass:"mobile-navigation__level-item-title"},[_vm._v(_vm._s(child.customTitle ? child.customTitle : child.title)+" ")]):_vm._e(),(child.children && child.children.length > 0)?_c('svg',{staticClass:"chevron-right",attrs:{"viewBox":"0 -256 1792 2016"}},[_c('path',{attrs:{"d":"M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z","fill":"currentColor"}})]):_vm._e()],2)}),(_vm.animationLevel > 1)?_c('a',{staticClass:"mobile-navigation__back",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeSubSet.apply(null, arguments)}}},[_c('v-icon',{attrs:{"name":"chevron-left"}}),_vm._v("Terug ")],1):_vm._e()],2)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }