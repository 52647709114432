<template>
  <div :class="classes">
    <div class="searchBar--input">
      <input
        ref="search"
        type="text"
        placeholder="Zoek direct naar..."
        name="search"
        @keyup.enter="search" />
      <img
        alt="Search icon"
        class="searchBar--input-icon"
        src="\assets\icons\search-dark.svg" />
    </div>
    <div class="searchBar--link">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      classes: {
        type: String,
        required: false,
        default: "search",
      },
    },
    methods: {
      search() {
        window.location.href =
          "/zoeken?term=" + this.$refs.search.value + "&page=1&mode=page";
      },
    },
  };
</script>
