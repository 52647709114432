<template>
  <div class="khn-search--container">
    <form>
      <SearchBox
        :parsed-term="searchTerm"
        placeholder="Zoek direct naar..."
        @input="changeQuery"></SearchBox>
    </form>

    <main>
      <SearchResults
        v-if="!!searchTerm && searchTerm.length"
        :parsed-term="searchTerm"
        :parsed-page="searchPage"
        :parsed-mode="searchMode"
        @changePage="changePage"
        @changeMode="changeMode"></SearchResults>
      <p v-else class="search-results-items__none-warn">
        Begin met zoeken door een zoekterm in te vullen.
      </p>
    </main>
  </div>
</template>

<script>
  import SearchBox from "./search/SearchBox.vue";
  import SearchResults from "./search/SearchResults.vue";

  export default {
    name: "KhnSearch",
    components: {
      SearchBox,
      SearchResults,
    },
    props: {
      parsedTerm: {
        type: String,
        default: null,
      },
      parsedPage: {
        type: Number,
        default: 1,
      },
      parsedMode: {
        type: String,
        default: "page",
      },
    },
    data() {
      return {
        searchTerm: null,
        searchPage: 1,
        searchMode: "page",
      };
    },
    computed: {
      searchQuery() {
        return `/zoeken?term=${this.searchTerm}&page=${this.searchPage}&mode=${this.searchMode}`;
      },
    },
    watch: {
      searchQuery() {
        this.updateUrlLocation();
      },
    },
    created() {
      this.searchTerm = this.parsedTerm ? this.parsedTerm : this.searchTerm;
      this.searchPage = this.parsedPage ? this.parsedPage : this.searchPage;
      this.searchMode = this.parsedMode ? this.parsedMode : this.searchMode;
    },
    methods: {
      changeQuery(e) {
        this.searchTerm = e;
      },
      changePage(e) {
        this.searchPage = e;
      },
      changeMode(e) {
        this.searchMode = e;
      },
      updateUrlLocation() {
        history.pushState(
          {},
          null,
          `?term=${this.searchTerm}&page=${this.searchPage}&mode=${this.searchMode}`
        );
      },
    },
  };
</script>
