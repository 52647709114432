<template>
  <div class="col col-6 newsItem no-margin no-padding">
    <div class="block block--newsItem newsItem-block">
      <div v-if="info.image && info.image.url" class="card-img-container">
        <a :href="info.cta.url" :target="info.cta.target">
          <div class="card-img-container__img">
            <img
              :src="info.image.url"
              class="image img-container__overlay"
              :alt="info.image.alt"
              :aria-label="info.image.alt" />
          </div>
        </a>
      </div>

      <div class="card-content">
        <div class="card-header">
          <h2 class="card-title">
            {{ info.title }}
          </h2>
        </div>

        <div class="card-slot stretch-slot">
          <div class="newsItem__meta">
            <span class="newsItem__meta-date">{{ info.date }}</span>
            <span
              v-for="tag in filteredTags"
              :key="`tag-${tag.id}`"
              class="newsItem__meta-tag">
              {{ tag.title }}
            </span>
          </div>

          <div class="newsItem__text">
            {{ info.text }}
          </div>
        </div>

        <a
          :href="info.cta.url"
          :target="info.cta.target"
          class="newsItem__link link">
          <i class="fas fa-icon fa-chevron-right"></i>
          <span>{{ info.cta.title }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NewsItem",
    props: {
      fallback: {
        type: Array,
        default: () => [],
      },
      info: {
        type: Object,
        required: true,
      },
      filters: {
        type: Array,
        required: true,
      },
    },
    computed: {
      filteredTags() {
        if (this.info && this.info.tags && this.filters.length > 0) {
          const filters = this.filters;
          const tags = this.info.tags.filter((i) => filters.includes(i.id));
          return tags.length > 0 ? tags : this.info.tags;
        }
        return this.fallback;
      },
    },
  };
</script>
