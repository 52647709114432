<template>
  <div class="testimonials">
    <div class="testimonials__title">
      <h2>{{ title }}</h2>
    </div>
    <div id="testimonials-glider" class="glide testimonials__glide">
      <div class="glide__track" data-glide-el="track">
        <div class="glide__slides">
          <div
            v-for="i in total"
            :key="i"
            class="testimonial__slide glide__slide">
            <slot :name="'testimonial_' + i"></slot>
          </div>
        </div>
      </div>
      <div class="testimonials__controls" data-glide-el="controls">
        <button class="testimonials__button" data-glide-dir="<">
          <i class="fas fa-icon fa-chevron-left"></i>
        </button>
        <button class="testimonials__button" data-glide-dir=">">
          <i class="fas fa-icon fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Glide from "@glidejs/glide";

  export default {
    name: "QouteCarousel",
    props: {
      totalItems: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        glide: null,
        peekValue: { before: 0, after: 0 },
      };
    },
    computed: {
      total() {
        return parseInt(this.totalItems);
      },
    },
    mounted() {
      this.glide = new Glide("#testimonials-glider", {
        type: "carousel",
        autoplay: 0,
        gap: 50,
        perView: 1,
        peek: this.peekValue,
      }).mount();
    },
  };
</script>
