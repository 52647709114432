<template>
  <svg class="chevron-right" viewBox="0 -256 1792 2016">
    <path
      d="M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z"
      fill="currentColor" />
  </svg>
</template>

<script>
  export default {
    name: "ChevronRight",
  };
</script>
