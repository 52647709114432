<template>
  <section class="container">
    <div class="navigation-wrapper">
      <!-- Navigation Top -->
      <div class="navigation-container">
        <a href="/" target="_self">
          <img alt="KHN Logo" class="navigation-logo" src="/assets/logos/khn_logo.svg" />
        </a>
        
        <div class="navigation-top">
          <a href="/zoeken">
            <img class="search-icon" alt="search icon" src="/assets/svg/search.svg" />
          </a>

          <div class="divider"></div>
        
          <ul v-if="menu.externalLinks" class="navigation-list">
            <li
              v-for="(externalLink, index) in menu.externalLinks"
              :key="index"
              :class="{ 'navigation-item': true, 't-navigation-small': !externalLink.redButton }"
            >
              <a :href="externalLink.url" :class="{ 'button-primary': externalLink.redButton }" target="_blank">{{
                externalLink.title
              }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Navigation Bottom -->
      <div class="navigation-container">
        <ul class="navigation-list">
          <li class="navigation-item t-navigation"><a :href="menu.overviewPage.url" class="t-bold">{{ menu.overviewPage.title }}</a></li>
          <div class="divider"></div>
          <li v-for="item in menu.mainNav" :key="item.title" class="navigation-item t-navigation">
            <a :href="item.url">{{ item.title }}</a>
          </li>
        </ul>

        <ul class="navigation-list">
          <li v-for="item in menu.subNav" :key="item.title" class="navigation-item t-navigation">
            <a :href="item.url" class="t-normal">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "NavigationDesktop",
    props: {
      menu: {
        type: Object,
        required: true,
      },
    },
  };
</script>
