<template>
  <div class="navigation-mobile">
    <div class="container content-wrapper">
      <a href="/" target="_self">
        <img alt="KHN Logo" class="navigation-logo" src="/assets/logos/khn_logo.svg" />
      </a>

      <div class="content">
        <button class="navigation-toggle" @click="onNavigationToggle">
          <img v-if="isNavigationOpen" class="cross-icon" alt="cross icon" src="/assets/svg/cross.svg" />
          <img v-else class="hamburger-icon" alt="hamburger icon" src="/assets/svg/hamburger.svg" />
        </button>
        <div class="divider"></div>
        <a href="/zoeken">
          <img class="search-icon" alt="search icon" src="/assets/svg/search.svg" />
        </a>
      </div>
    </div>

    <NavigationModal :menu="menu" :isNavigationOpen="isNavigationOpen" v-scroll-lock="isNavigationOpen" />
  </div>
</template>

<script>
  import NavigationModal from './NavigationModal.vue'

  export default {
    name: "NavigationMobile",
    components: {
      NavigationModal
    },
    props: {
      menu: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isNavigationOpen: false, 
      };
    },
    methods: {
      onNavigationToggle() {
        this.isNavigationOpen = !this.isNavigationOpen;
      },
    }
  };
</script>
