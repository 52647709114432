<template>
  <img
    :data-src="img.url"
    class="image lozad"
    :class="addedClass"
    :alt="img.alt"
    :aria-label="img.alt" />
</template>

<script>
  import lozad from "lozad";

  export default {
    name: "LozadImageVue",
    props: {
      addedClass: {
        type: String,
        required: false,
        default: null,
      },
      img: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: true,
      };
    },
    mounted() {
      const setLoadingState = () => {
        this.loading = false;
      };
      this.$el.addEventListener("load", setLoadingState);
      this.$once("hook:destroyed", () => {
        this.$el.removeEventListener("load", setLoadingState);
      });
      const observer = lozad(this.$el);
      observer.observe();
    },
  };
</script>
