<template>
  <div class="loading-state">
    <div class="spinner-container">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LoadingState",
  };
</script>
