<template>
  <div class="banner glide" :class="{ 'single-glide': total < 2 }">
    <div class="glide__track" data-glide-el="track">
      <div class="glide__slides">
        <div v-for="i in total" :key="i" class="glide__slide">
          <slot :name="`slide_${i}`"></slot>
        </div>
      </div>
    </div>
    <div
      v-if="total > 1"
      class="glide__controls container"
      :class="{ 'glide__controls--overide': overideNumber !== null }"
      data-glide-el="controls[nav]"
    >
      <button
        v-for="i in total"
        :key="-i"
        :class="{ 'glide__bullet--force-active': overideNumber === i }"
        :data-glide-dir="'=' + (i - 1)"
        class="glide__bullet"
        @click="overide(i)"
      ></button>
    </div>
  </div>
</template>

<script>
  import Glide from "@glidejs/glide";

  export default {
    name: "HeroCarousel",
    props: {
      totalItems: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        overideNumber: null,
        glide: null,
        peekValue: { before: 0, after: 0 },
      };
    },
    computed: {
      total() {
        return parseInt(this.totalItems);
      },
    },
    mounted() {
      if (parseInt(this.totalItems) > 1) {
        this.glide = new Glide(".glide", {
          type: "carousel",
          autoplay: 5000,
          gap: 0,
          perView: 1,
          peek: this.peekValue,
        }).mount();
      } else {
        this.glide = new Glide(".glide", {
          type: "carousel",
          autoplay: 0,
          gap: 0,
          perView: 1,
          peek: this.peekValue,
          swipeThreshold: false,
          dragThreshold: false,
          keyboard: false,
        }).mount();

        // lazy fix so it fixes its self. yay.
        const resizeEvent = new Event("resize");
        window.dispatchEvent(resizeEvent);
      }
    },
    methods: {
      overide(i) {
        if (this.overideNumber === null) {
          this.overideNumber = i;
          const _this = this;
          window.setTimeout(() => {
            _this.overideNumber = null;
          }, 500);
        }
      },
    },
  };
</script>
