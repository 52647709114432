<template>
  <div class="relatedNews">
    <div v-if="info.filter" class="col col-12 newsOverviewList no-margin">
      <div class="block block--newsOverviewList newsOverviewList-block">
        <div class="newsOverviewList__filters">
          <h2>
            {{ info.filter.title }}
          </h2>

          <p>
            {{ info.filter.text }}
          </p>

          <ul v-if="showFilters" class="newsOverviewList__filters__list">
            <li
              v-for="(filter, j) in info.filter.filterOptions"
              :key="`filterItem-${j}`"
              class="newsOverviewList__filters__list-item">
              <div class="checkbox-container">
                <div class="checkbox">
                  <label :for="`item_${j}`">
                    <input
                      :id="`item_${j}`"
                      v-model="activeFilters"
                      type="checkbox"
                      :value="filter.id" />
                    <span class="checkmark"></span>
                    <span class="title">{{ filter.title }}</span>
                  </label>
                </div>
              </div>
            </li>
          </ul>

          <button
            class="newsOverviewList__filters__list-button link"
            @click="toggleFilterButton()">
            <i
              class="fas fa-icon"
              :class="showFilters ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
            <span>{{ filterButtonText }}</span>
          </button>
        </div>
      </div>
    </div>

    <ul class="newsOverviewList__list row">
      <li
        v-for="(news, i) in results"
        :key="`newsItem-${i}`"
        :class="{
          'newsOverviewList__list-item--small':
            info.columns && info.columns > 2,
        }"
        class="newsOverviewList__list-item">
        <NewsItem
          :info="news"
          :filters="activeFilters"
          :fallback="info.fallback || []"></NewsItem>
      </li>
    </ul>

    <button
      v-if="totalResults > amountShown"
      class="newsOverviewList__list-button link"
      @click="showMoreArticles()">
      <i class="fas fa-icon fa-chevron-right"></i>
      Meer artikelen tonen
    </button>
  </div>
</template>

<script>
  import axios from "axios";
  import NewsItem from "./NewsItem.vue";

  export default {
    name: "NewsOverviewList",
    components: {
      NewsItem,
    },
    props: {
      info: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        amountShown: 0,
        activeFilters: [],
        showFilters: false,
        loading: true,
        query: {
          section: [],
          related_to: [],
          page: 1,
          limit: 12,
        },
        results: [],
        totalResults: 0,
      };
    },
    computed: {
      filterButtonText() {
        return this.showFilters
          ? "Verberg filter"
          : "Activeer filter en toon alle afdelingen";
      },
    },
    watch: {
      activeFilters() {
        if (!this.loading) {
          this.resetSearch();
        }
      },
    },
    mounted() {
      this.query.section = this.info.sections || this.query.sections;
      this.query.related_to = this.info.related_to || this.query.related_to;
      this.query.limit = this.info.limit || this.query.limit;
      this.loading = false;
      this.resetSearch();
    },
    methods: {
      resetSearch() {
        this.results = [];
        this.totalResults = 0;
        this.amountShown = this.info.limit || this.query.limit;
        this.getQueryResults();
      },
      showMoreArticles() {
        this.amountShown += this.query.limit;
        this.query.page++;
        this.getQueryResults();
      },
      toggleFilterButton() {
        this.showFilters = !this.showFilters;
      },
      // Query results
      getQueryResults() {
        if (!this.loading && this.query.page >= 1) {
          this.loading = true;
          const self = this;
          const filters =
            self.activeFilters && self.activeFilters.length > 0
              ? self.activeFilters.join(",")
              : null;
          axios
            .get(
              `/api/search-by-section?section=${
                self.query.section
              }&related_to=${self.query.related_to.map((e) => e).join(",")}${
                filters ? "&filters=" + filters : ""
              }&page=${self.query.page}&limit=${self.query.limit}`
            )
            .then(function (response) {
              self.results = self.results.concat(response.data.results);
              self.totalResults = response.data.totalCount;
              self.loading = false;
            })
            .catch(function () {
              self.loading = false;
            });
        }
      },
    },
  };
</script>
